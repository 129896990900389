@import '../../styles/_variables.scss';

.container-unavailable {
	display: flex;
	flex-direction: column;
	height: 100%;

	.unavailable {
		background-color: $secondary_color;
		height: calc(100% - 90px);
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;

		@media screen and (max-width: 990px) {
			margin-top: -80px;
			padding-top: 60px;
		}
		
		.logo {
			margin: 30px 0 0 0;
			text-align: center;
			svg, img {
				margin: auto;
			}
		}

		.text-1, .text-2 {
			margin: 10px 0px 40px;

			p {
				font-family: $primary_font_family;
				font-style: normal;
				font-weight: 500;
				font-size: 15px;
				line-height: 24px;
				letter-spacing: 0.2em;
				color: $light_gray;
			}
		}
					
		.text-2 p {
			letter-spacing: 0.05em;	
			font-size: 20px;
			text-align: center;
		}
	}
}