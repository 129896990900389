@import "../../styles/_variables.scss";

* {
  font-family: $primary_font_family;
  transition: all 0.2s ease-in-out;
}

.text-selego {
  color: #000;
}

.text-whites{
  color: #fff !important;
}

.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}
.fs-13 {
  font-size: 13px;
}
.fs-16 {
  font-size: 16px;
}
.fs-32 {
  font-size: 32px;
}
.ls-3 {
  letter-spacing: 3px;
}
.lh-2 {
  line-height: 2rem;
}
.bgimg {
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  background-position-y: -240px;
  background-repeat: no-repeat;
}
.bf-b-50 {
  backdrop-filter: blur(50px);
}

.no-underline-a a {
  color: #ffffff;
}


@media (min-width: 1024px) {
  .px-30 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .mx-30 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
}
