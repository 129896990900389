@import "../../styles/_variables.scss";

.container-sign-up {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(
    132.08deg,
    #000000 -28.34%,
    var(--primary-color) 100%
  );

  .sign-up {
    height: calc(100% - 90px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 990px) {
      margin-top: -80px;
      padding-top: 60px;
    }
    &.success {
      button,
      button:hover {
        border: 1px solid white;
        background-color: white;
        border-radius: 0px;
        width: 255px;
        height: 50px;
        color: $secondary_color;
        font-family: $primary_font_family;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        margin: 30px 0 0 0;
        text-transform: uppercase;
      }
    }

    .logo {
      margin: 90px 0 0 0;
      text-align: center;
      svg,
      img {
        margin: auto;
      }
    }

    .text-1 {
      margin: 15px 0px 30px;
      span {
        font-family: $primary_font_family;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 24px;
        letter-spacing: 0.2em;
        color: white;
      }
    }

    .text-2 {
      margin: 10px 0px 20px;
      text-align: center;

      span {
        font-family: $primary_font_family;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 37px;
        color: white;
        text-transform: uppercase;
      }
    }

    input[type="checkbox"] {
      width: auto !important;
      height: auto !important;
    }

    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="text"]:focus,
    input[type="password"]:focus,
    input[type="email"]:focus {
      background-color: transparent;
      border: 1px solid #fff;
      border-radius: 0px;
      font-family: $primary_font_family;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      width: 255px;
      height: 57px;
      color: #fff;
    }

    input::placeholder {
      text-transform: uppercase;
    }

    .form-check-label,
    .form-check-label a {
      font-family: $primary_font_family;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: #fff;
    }
    .form-check-label a {
      color: $light_gray;
    }

    button,
    button:hover {
      border: 1px solid $primary_color;
      background-color: $primary_color;
      border-radius: 0px;
      width: 255px;
      height: 57px;
      font-family: $primary_font_family;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 24px;
      margin: 30px 0 0 0;
      letter-spacing: 0.2em;
    }

    .forgot-password {
      margin-top: 10px;

      a {
        font-family: $primary_font_family;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        text-decoration: underline;
        color: $link_color;
      }

      a:hover {
        color: $link_color;
      }
    }

    .dont-have-account {
      margin-top: 30px;

      a {
        font-family: $primary_font_family;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        text-decoration: underline;
        color: $link_color;
      }

      a:hover {
        color: $link_color;
      }
    }

    .trial {
      margin-top: 20px;
      width: 255px;
      height: 57px;
      margin-bottom: 50px;

      .col {
        padding: 0;
      }

      button {
        font-family: $primary_font_family;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        border: 1px solid $primary_color;
        background-color: transparent;
        margin: 0px;
        color: #fff;
        letter-spacing: 0.2em;
      }
    }

    .group-password-sign-up {
      position: relative;

      .bi-eye {
        position: absolute !important;
        top: 20px;
        right: 10px;
      }

      .bi-eye-slash {
        position: absolute !important;
        top: 20px;
        right: 10px;
      }
    }

    .text-danger {
      text-align: right;
      font-family: $primary_font_family;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      margin-top: 5px;
      width: 255px;
    }
  }
}

