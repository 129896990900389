@import "~bootstrap/scss/bootstrap";

@media (min-width: 768px) {
  .cast-member-details {
    .md\:text-left {
      text-align: left !important;
    }
  }
}

.tribes-media-audios-list {
  margin-top: 30px;
}

body {
  .tribes-checkbox a {
    text-decoration: underline !important;
    color: white;
  }
  .tribes-checkbox a:hover {
    text-decoration: none !important;
  }

  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-weight: 300;
  background-color: var(--background-color) !important;
}

body {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#2c83b9', endColorstr='#1eabe3', GradientType=1);
}

#wrapper {
  height: 100vh;
}

#banner {
  border-bottom: 1px solid #fff;
  background-color: #1eabe3;
  width: 100%;
}

#banner h1 {
  margin: 0;
  padding: 30px;
}

.logo {
  padding: 10px;
  width: 25%;
  min-width: 350px;
  float: left;
  margin: auto;
}

.title {
  width: 75%;
  white-space: nowrap;
}

.clear {
  clear: both;
}

.content {
  margin-bottom: 10em;
}

h1,
h2,
h3,
p {
  font-weight: 300;
}

#player {
  max-width: 900px;
  width: 90%;
  margin: auto;
  -webkit-box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 56px 0px rgba(0, 0, 0, 0.75);
}

a {
  color: #97d9ef;
  font-weight: 400;
}

a:hover {
  color: #fff;
}

.content-toast {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btns-container {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}

.confirm-btn {
  font-family: Poppins;
  width: 80px;
  height: 40px;
  border: unset;
  background-color: #5e00f6;
  color: #fff;
}
.cancel-btn {
  font-family: Poppins;
  width: 80px;
  height: 40px;
  border: unset;
  background-color: red;
  color: #fff;
}

.text-toast {
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #000;
}

@media screen and (min-width: 768px) {
  .no-padding-large {
    padding: 0 !important;
  }
}
@media screen and (max-width: 768px) {
  .no-padding-responsive {
    padding: 0 !important;
  }

  .no-margin-horizontal-responsive {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
