@import 'bootstrap';

.back-topbar-selego {
  background-color: var(--primary-color);
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 13;
  padding: 0 8% !important;

  .flag-mobile {
    margin: auto 0;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    cursor: pointer;
    padding-left: 5px;
    .flag-icon {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      background-size: cover;
    }
  }
  > div {
    display: flex;
    justify-content: space-between;
  }
  .back-btn {
    cursor: pointer;
  }
  .custom-logo {
    display: flex;
    cursor: pointer;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 8%;
  }
  .header-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 91%;
    .links-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .categories,
      .buttons {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        a {
          color: #ffffff;
        }
        .nav-link {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 0.5rem 0.8rem;
          span {
            font-family: var(--font-family);
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0.2em;
            line-height: 24px;
            text-transform: uppercase;
          }
          svg {
            margin-right: 10px;
          }
          &.active,
          &:hover {
            color: var(--primary-color);
            font-weight: bold;
          }
        }
        .hack:hover {
          color: black;
          font-weight: bold;
        }
      }

      .buttons {
        Button {
          margin: 0px 10px;
          border-radius: 0px;
          padding: 10px 20px;

          &.login {
            font-family: var(--font-family);
            margin: 0px 10px;
            border-radius: 0px;
            border: 1px solid var(--primary-color);
            background: transparent;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
          }
          &.login-selego {
            border-color: var(--alternative-color);
            color: white !important;
          }

          &.signup {
            font-family: var(--font-family);
            background: var(--primary-color);
            margin: 0px 10px;
            border-radius: 0px;
            border: 1px solid var(--primary-color);
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            text-transform: uppercase;
            letter-spacing: 0.2em;
          }
        }
        .signup-selego {
          background: var(--alternative-color) !important;
        }

        .lang-selector {
          display: flex;
          flex-direction: column;
          button {
            font-family: var(--font-family);
            margin: 0px 10px;
            border-radius: 0px;
            color: white;
            background: none;
            border: none;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            text-align: center;
            width: auto !important;
            &:focus {
              outline: none;
            }
            span {
              margin-left: 5px;
            }
          }
        }

        #my-account-dropdown {
          font-family: var(--font-family);
          color: var(--alternative-color);
          margin: 0px 10px;
          border-radius: 0px;
          background: none;
          border: none;
          font-style: normal;
          font-weight: bold;
          font-size: 15px;
          line-height: 24px;
          letter-spacing: 0.2em;
          text-transform: uppercase;
          width: 193px;
          &:focus {
            box-shadow: none !important;
          }
        }

        .dropdown-menu {
          background: transparent;
          text-transform: uppercase;
          top: 14px !important;
          left: 14% !important;
          border-radius: 0 !important;
          border: 1px solid transparent;
          padding: 0 !important;
          .dropdown-divider {
            border-top: 1px solid transparent !important;
          }
          .my-account-option {
            padding: 0 1.5rem;
            justify-content: center;
            align-items: center;
            &.logout {
              background: var(--primary-color);
              border: solid black 8px;
              &:hover span {
                color: white;
              }
            }
            &.selego-logout {
              border-color: var(--primary-color) !important;
              background: black !important;
              color: var(--primary-color) !important;
              & span {
                color: var(--primary-color) !important;
              }
              &:hover span {
                color: white !important;
              }
            }
          }
          .my-account-option,
          .dropdown-item {
            font-family: var(--font-family);
            background-color: transparent;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            color: white;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            &:hover span {
              color: var(--primary-color);
            }
          }
          .selego-back {
            background: var(--primary-color) !important;
            &:hover span {
              color: black;
            }
          }
          .dropdown-item {
            height: 51px;
            background-color: black;
            text-align: center;
            display: flex;
            position: relative;
            span {
              color: white;
            }
            &:after {
              background: linear-gradient(
                to right,
                #000000 0%,
                var(--primary-color) 100%
              );
              content: '';
              display: block;
              height: 1px;
              width: 100%;
              position: absolute;
              bottom: 0;
            }
          }
          button {
            margin: 0 !important;
            .logout-icon {
              margin-right: 8px;
            }
          }
        }

        .dropdown-flags {
          transform: translate3d(-25%, 46px, 0px) !important;
          .dropdown-item {
            background: var(--primary-color);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .flag-icon {
              margin-right: 8px;
            }
            &:hover span {
              color: black !important;
            }
          }
        }
      }
    }
  }
  .hamburger-button {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
.header-submenu {
  margin-top: 90px;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0%;
  z-index: 11;
  background-color: #1e2022;
  .cmd-movie-divider {
    height: 1px;
    width: 100%;
    background: linear-gradient(to right, #1e2022, var(--primary-color) 90%);
  }
  .logged {
    .option-languague {
      width: 100%;
      height: 57px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2em;
      color: #1e2022;
      display: flex;
      align-items: center !important;
      justify-content: center;
      text-transform: uppercase;
      cursor: pointer;
      .arrow-img {
        filter: invert(1);
        width: 12px;
      }
    }
    .my-account-option {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .option {
        width: 100%;
        height: 57px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.2em;
        color: #ffffff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #1e2022;
        cursor: pointer;
      }
    }
    .flag-mobile {
      width: 100%;
      .flag-icon {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-size: cover;
      }
    }
    .option {
      width: 100%;
      height: 57px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2em;
      color: #1e2022;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      cursor: pointer;
      .arrow-img {
        filter: invert(1);
        width: 12px;
      }
    }
  }
  .flag-icon {
    margin-right: 8px;
  }
  .not-logged {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .d-flex-l {
      display: flex !important;
      justify-content: space-between !important;
    }
    .button-login {
      margin-top: 10px;
      width: 90vw !important;
      background: var(--primary-color);
    }
    .option {
      width: 100%;
      height: 57px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2em;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid var(--primary-color);
      text-transform: uppercase;
      cursor: pointer;
    }
    .option-trial {
      width: 80%;
      height: 57px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1px solid #a09c9c;
      text-transform: uppercase;
      margin-top: 50px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2em;
      color: #1e2022;
    }
    .flag-mobile {
      width: 100%;
      .flag-icon {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        background-size: cover;
      }
    }
    .option-languague {
      width: 100%;
      height: 57px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2em;
      color: #1e2022;
      display: flex;
      align-items: center !important;
      justify-content: center;
      border-bottom: 1px solid var(--primary-color);
      text-transform: uppercase;
      cursor: pointer;
      .arrow-img {
        filter: invert(1);
        width: 12px;
      }
    }
    .my-account-option {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .option {
        width: 100%;
        height: 57px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.2em;
        color: #ffffff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #1e2022;
        cursor: pointer;
      }
    }
  }
  .log-out-submenu {
    margin: 10px auto 0 auto;
    height: 57px;
    width: 90vw;
    background: var(--primary-color);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .log-out-submenu img {
    margin-left: 10px;
  }
  .myst-hack {
    filter: invert(1);
  }
}
.overlay-submenu {
  position: fixed;
  top: -90;
  bottom: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  .submenu-selego {
    background-color: var(--primary-color) !important;
    .logged {
      .option {
        background-color: var(--primary-color) !important;
      }
    }
    .not-logged {
      .option {
        background-color: var(--primary-color) !important;
      }
      .button-login {
        width: 90% !important;
        background: black !important;
        color: var(--primary-color) !important;
      }
    }
    .log-out-submenu {
      width: 90% !important;
      border: black !important;
      background: black !important;
      color: var(--primary-color) !important;
    }
  }
}

.footer-categories {
  position: fixed;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  background-color: #000;
  z-index: 1009;
  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    justify-content: space-between;
    width: 100%;
  }
  a {
    color: #ffffff;
  }
  .nav-link {
    font-family: var(--font-family);
    font-style: normal;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
    padding: 5px;
    letter-spacing: 0.2em;
    svg {
      margin-right: 5px;
    }
    &.active {
      color: var(--primary-color);
      font-weight: bold;
    }
    
  }
}

a.nav-link.active.active-selego {
  color: var(--trial-color) !important;
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-down(md) {
  .header {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    align-items: center;
    justify-content: space-between;
    padding: 10px !important;
    .toggleButton {
      margin: auto 10px auto 0;
      padding-left: 10px;
    }
    .btn-close {
      width: 24px;
      margin-right: 4px;
    }
    .custom-logo {
      width: auto;
      justify-content: center;
    }
    .container-lg {
      padding: 0 !important;
    }
    .header-content {
      width: 100%;
      .links-section {
        .categories {
          width: 100%;
          .links {
            margin-right: 0 !important;
            flex-direction: row;
            margin-left: 15px;
            width: 100%;
            justify-content: space-between;
            a.nav-link {
              flex-direction: column;
              padding: 10px 5px;
              svg {
                margin-right: 0;
              }
              span {
                font-size: 11px;
                line-height: 15px;
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
  .back-topbar-selego {
    background: linear-gradient(
      0deg,
      var(--primary-color) 0%,
      var(--primary-color) 100%
    );
  }
}

@media screen and (max-width: 500px) {
  .header .header-content .links-section .categories .links a.nav-link span {
    font-size: 10px;
    letter-spacing: 0;
  }
}

@media screen and (max-width: 340px) {
  .header .header-content .links-section .categories .links a.nav-link span {
    font-size: 8px;
  }
}

@media screen and (max-width: 575px) {
  .header
    .header-content
    .links-section
    .categories
    .links
    .nav-link
    .active-selego:hover {
    color: var(--primary-color) !important ;
  }
  .header .header-content .links-section .categories .links .hidden-about {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .back-topbar-selego {
    background: var(--primary-color);
  }
}
