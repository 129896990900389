@import 'bootstrap';
@import '../../styles/_variables.scss';
.main-selego {
  margin-top: 0 !important;
}
.main-item {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 590px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    margin-top: -90px;
  }

  .overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, #000000 100%);
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  .row {
    margin: 0;
    width: 100%;
  }

  .col {
    @media screen and (max-width: 768px) {
      padding: 0 40px !important;
    }
    &.container-data {
      padding: 20px 98px 0px 95.89px !important;
    }
  }

  .play-button {
    padding: 10px 15px;
    display: inline-flex;
    align-items: center;
    color: white;
    margin-right: 30px;
    background-color: var(--primary-color);
    @media screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .play {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
    .label {
      text-transform: uppercase;
      font-family: $primary_font_family;
      font-weight: 500;
      margin-left: 5px;
      font-size: 15px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2em;
    }
  }

  .category {
    font-family: $primary_font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.1;
    color: white;
    border: solid 2px white;
    border-radius: 3px;
    letter-spacing: 0.1em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0 30px 0;
    padding: 5px 10px;
    min-height: 28.49px;
    flex-direction: row;
    text-transform: uppercase;
  }


  .title-movie {
    font-family: $primary_font_family;
    font-style: normal;
    font-size: 30px;
    line-height: 29px;
    color: #fff;
    margin-bottom: 10px;
    font-weight: bold !important;
    text-transform: uppercase;
  }

  .author {
    font-family: $primary_font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2em;
    color: #fff;
    text-transform: uppercase;
  }

  .year {
    font-family: $primary_font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2em;
    color: #fff;
    text-transform: uppercase;
  }

  .list-year {
    margin-bottom: 15px;
  }

  .year-detail {
    margin-bottom: 30px;
  }

  .resume {
    max-width: 700px;
    font-family: $primary_font_family;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #fafafa;
    margin-bottom: 30px;
  }
  .extra-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 31.51px;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    .extra-container {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-bottom: 15px;
    }
    .resume {
      margin-bottom: 25px;
    }
    .play-button {
      margin-bottom: 50px;
    }
    .category {
      margin-top: 7px;
      padding: 10px;
    }
  }

  .extra {
    display: flex;

    .quality {
      margin-right: 20px;
    }

    .mins {
      font-family: $primary_font_family;
      font-style: normal;
      font-weight: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 15px;
      color: #e5e5e5;
      margin-right: 15px;
      text-transform: uppercase;

      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    .audio,
    .cc {
      font-family: $primary_font_family;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 15px;
      color: #e5e5e5;
      margin-right: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: uppercase;
      svg {
        margin-right: 5px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .main-item {
    .col {
      &.container-data {
        padding: 20px 20px 0px 20px !important;
      }
    }
  }
}
