.login-modal {
  background: linear-gradient(
    132.08deg,
    #000000 -28.34%,
    var(--color-primary) 100%
  );
  display: flex;
  align-items: center;
}

.login-modal-selego {
  background: linear-gradient(
    132.08deg,
    var(--primary-color) -28.34%,
    var(--primary-color) 100%
  );
}

.login-modal svg path {
  fill: white;
}

.login-modal h3 {
  line-height: 24px;
  letter-spacing: 0.2em;
  opacity: 0.8;
}

.color-label {
  color: var(--alternative-color) !important;
  background: black; 
}
.new-brand {
  color: black !important;
}
.new-brand-btn {
  background: black;
  color: var(--primary-color);
}
