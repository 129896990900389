@import "bootstrap";
@import "../../styles/_variables.scss";

.container-list-movies {
  display: flex;
  flex-direction: column;
  background-color: var(--background-color) !important;
  .list-category {
    font-family: $primary_font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.1;
    letter-spacing: 0.1em;
    color: white;
    border: solid 2px white;
    border-radius: 3px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 7px 0;
    padding: 5px 10px;
    min-height: 28.49px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
  }
  .cast-section {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  @media screen and (max-width: 990px) {
    .container-lg {
      padding: 0 !important;
      .row {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .cast {
      margin: 0;
    }
    .author-col {
      padding: 0;
    }
  }

  .list-movies {
    .infinite-scroll-component {
      overflow-x: hidden !important;
    }

    @media screen and (min-width: 768px) {
      margin-top: 30px;
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 40px;
    }

    .movie {
      margin: 20px 0;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      border-radius: 0 !important;
      flex-direction: column;
      justify-content: flex-end;
      height: 260px;
      overflow: hidden;
      padding: 0;
      img {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: center;
        transition: transform 2s, filter 1.5s ease-in-out;
        transform-origin: center center;
        filter: brightness(60%);
      }
      &:hover {
        img {
          filter: brightness(100%);
          transform: scale(1.3);
        }
      }
      .overlay {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 29.69%,
          #000000 100%
        );
        display: flex;
        align-items: flex-end;
        height: 100%;
      }

      .overlay-selego {
        display: flex;
        align-items: flex-end;
        height: 100%;
      }

      .container-data {
        width: 100%;
        padding: 10px 20px 20px 5.33%;

        .titleMovie {
          font-family: $primary_font_family;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
        }

        .author {
          font-family: $primary_font_family;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          letter-spacing: 0.2em;
          color: #fff;
        }

        .year {
          font-family: $primary_font_family;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          letter-spacing: 0.2em;
          color: #fff;
          margin-bottom: 5px;
        }
      }
    }
  }

  .cast-col {
    padding: 0 !important;
    margin: 0 !important;
  }
}

@include media-breakpoint-down(md) {
  .container-list-movies {
    .list-movies {
      .movie {
        margin: 0px;
      }
    }
  }
}
