@import 'bootstrap';
@import '../../styles/_variables.scss';

.author-detail {
  // margin: 30px 0;
  // border: 2px solid;

  .row {
    margin: 0;
  }

  .col-icon {
    min-height: 156px;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .col-content {
    padding: 30px 0px;
    min-height: 156px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .selego-text {
      color: black !important;
    }

    .title-author {
      font-family: var(--font-family);
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 29px;
      color: #fff;
      text-transform: uppercase;
    }
    .content-author {
      font-family: var(--font-family);
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #fff;
      text-transform: uppercase;

      a {
        font-family: var(--font-family);
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #fff;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
    .more-author {
      font-family: var(--font-family);
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #fff;
      text-align: right;
    }
  }
  @media screen and (max-width: 990px) {
    margin: 0;
    border: none;
    .col-content {
      padding: 20px 40px 60px 40px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .col-icon {
    border-bottom: 1px solid #fff;
    border-right: none !important;
  }
}
