.cast-member-details {
  max-width: 700px;
  margin: 0 auto;
}

.cast-member-details .cmd-image {
  filter: grayscale(100%);
}

.cast-member-details .cmd-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}

.cast-member-details .cmd-movie-divider {
  height: 1px;
  width: 100%;
  background: linear-gradient(to left, #000000, var(--primary-color) 100%);
}
