.bm-blur {
  z-index: 14;
  backdrop-filter: blur(3px);
  background-color: rgba(45, 58, 93, 0.5);
}
.bm-blur-fow {
  margin-top: 90px;
  z-index: 11;
}
.top-bar {
  background: #1a1a1a;
  width: 100vw;
  height: 90px;
  position: absolute;
  top: -90px;
}
.bm-body-container {
  outline: none;
  max-height: 100vh;
  max-width: 90%;
}
.bm-small {
  width: 400px;
}
.bm-medium {
  width: 525px;
}
.bm-body-flow {
  width: 100%;
}
.bm-max {
  width: 100%;
  padding: 0 10% !important;
}
.bm-close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.ReactModal__Body--open {
  overflow-y: hidden;
}
.ReactModal__Overlay {
  overflow: scroll;
}
.ReactModal__Overlay--after-open {
  overflow: scroll;
}
.bm-close-max {
  max-width: 1140px;
  width: 100%;
  margin: 35px auto 0 auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 190px;
}
.close-right {
  color: #5b595f;
  height: 24px;
  line-height: 24px;
  font-size: 13px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

@media (max-width: 1400px) {
  .bm-close-max {
    padding-right: 95px;
  }
}
@media (max-width: 1100px) {
  .bm-close-max {
    padding-right: 45px;
  }
}
@media (max-width: 768px) {
  .bm-max {
    padding: 0 !important;
  }
  .bm-close-max {
    padding-right: 6vw;
    margin-top: 10px;
  }
}
