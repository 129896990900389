.tribes-input {
  border-radius: 3px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.05em;
  background: rgba(255, 255, 255, 0.7);
}

.tribes-input::placeholder {
  text-transform: uppercase;
  color: inherit;
  opacity: 0.65;
}
