.tribes-audio-player-container {
  width: 100%;
  position: fixed;
  bottom: 0px;
  background-color: var(--primary-color);
  z-index: 10;
  left: 0;
}

.tribes-audio-player {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  min-height: 5rem;
}

body.tribes-media-audio-player--open {
  padding-bottom: 5rem;
}

@media (min-width: 640px) {
  .tribes-audio-player {
    min-height: 6rem;
  }
  body.tribes-media-audio-player--open {
    padding-bottom: 6rem;
  }
}

.ap-progress-animation {
  transition: width 250ms ease-in;
}

@media (min-width: 992px) {
  .tribes-audio-player {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .tribes-audio-player {
    max-width: 1140px;
  }
}
