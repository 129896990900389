.offer-modal {
  background: linear-gradient(
    132.08deg,
    #000000 -28.34%,
    var(--color-primary) 100%
  );
  display: flex;
  align-items: center;
}

.offer-modal svg path {
  fill: white;
}

.label-img {
  max-width: 30%;
}
