@import "bootstrap";
@import "../../styles/_variables.scss";
.banner-black {
  color: #ff5e20 !important;
  background: var(--alternative-color) !important;
  border: 2px solid #ff5e20 !important;
  
  &.selego-banner {
    background: transparent !important;
    border: 2px solid var(--primary-color) !important;
    color: var(--primary-color) !important;
  }

  @media screen and (max-width: 575px) {
    color: var(--primary-color) !important;
    background: var(--alternative-color) !important;
    border: 2px solid var(--primary-color) !important;
  }
}
.banner-subscription {
  background: var(--primary-color);
  font-family: var(--font-family);
  padding: 17px 25px;
  margin-top: 40px;
  color: white;

  @media screen and (min-width: 768px) {
    padding: 25px 40px 10px 50px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }

  .title-1Banner {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 29px;
    text-transform: uppercase;
  }

  .row > div {
    padding: 0;
  }

  .banner-text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }
  .sub-cancel-label {
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25;
    margin-top: 5px;
    text-align: center;
  }
  .title-2Banner {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    letter-spacing: 0.1em;
    margin: 10px 0 0 0;
  }

  .col-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    button.button-try-free {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.2em;
      background-color: white;
      border-color: white;
      border-radius: 0px;
      padding: 12px 30px;
      width: 100%;
      text-transform: uppercase;
      &:hover {
        background-color: rgb(231, 231, 231);
      }
    }
    .button-try-free-selego {
      background-color: var(--primary-color) !important;
      border-color: var(--primary-color) !important;
      color: var(--alternative-color) !important;
      @media screen and (max-width: 575px) {
        background-color: transparent !important;
        border-color: var(--primary-color) !important;
      }
    }
  }
}
