.title-1 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 29px;
  text-transform: uppercase;
  color: var(--primary-color);
}
.title-2 {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.1em;
  margin: 10px 0 0 0;
  color: var(--primary-color);
}
.title-3 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  color: white;
}
.title-4 {
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  text-align: left;
  letter-spacing: 0.1em;
  margin: 5px 0 0 0;
  color: white;
}
.bg-app-primary {
  background-color: var(--primary-color);
}

.bg-app-secondary {
  background-color: var(--trial-color) !important;
}

.col-container {
  @media screen and (max-width: 768px) {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
}
.slides {
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
}
.slides::-webkit-scrollbar {
  display: none;
}
.left-arrow {
  top: 0;
  background: linear-gradient(90deg, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}
.right-arrow {
  top: 0;
  right: 0;
  transform: rotateY(180deg);
  background: linear-gradient(90deg, rgb(0, 0, 0), rgba(0, 0, 0, 0));
}
html {
  scroll-padding-top: 200px;
}
.slide {
  scroll-snap-align: start;
  flex-shrink: 0;
  transform-origin: center center;
  transform: scale(1);
  transition: transform 0.5s;
  scroll-margin-top: 200px;
}
