body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body .px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

button:hover {
  opacity: 0.7;
}
button:focus {
  box-shadow: none !important;
}
.black-color {
  color: var(--black) !important;
}

.primary-color {
  color: var(--primary-color) !important;
}

.black-border-color {
  border-color: var(--black) !important;
}

.primary-border-color {
  border-color: var(--primary-color) !important;
}

.dark-orange-border-color {
  border-color: var(--dark-orange) !important;
}

.black-background-color {
  background-color: var(--black) !important;
}

.dark-orange-background-color {
  background-color: var(--dark-orange) !important;
}

.primary-background-color {
  background-color: var(--primary-color) !important;
}
body .px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.tribes-media-dropdown .Dropdown-menu {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  display: block;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  min-width: auto;
}

@media (min-width: 640px) {
  body .sm\:px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  body .sm\:mr-4 {
    margin-right: 1rem !important;
  }
  body .sm\:p-0 {
    padding: 0 !important;
  }
  body .sm\:ml-6 {
    margin-left: 1.5rem !important;
  }
}
