.color-glitch {
  color: var(--primary-color);
}

.tyc-glitch a:hover {
  color: var(--primary-color);
}
.btn-confirm-glitch {
  background-color: var(--primary-color);
  color: #1a1a1a;
}
.btn-confirm-glitch:hover:not([disabled]) {
  color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
}
.b-glitch {
  background: var(--primary-color) !important;
  color: #000000;
}
.force-black {
  color: black !important;
}

.flow-subs {
  background: linear-gradient(to right, #000000 10%, #1a1a1a 100%);
  min-height: 100vh;
}
.main-box {
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  margin: 40px auto 0 auto;
  max-width: 1140px;
  width: 100%;
}
.second-box {
  position: relative;
}
.start-free {
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  text-transform: uppercase;
}
.ms-free {
  margin: 20px 0 40px 0;
  height: 48px;
  font-weight: 500;
  font-size: 21px;
  line-height: 24px;
}
.step-at {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 34px;
}
.step-at span {
  margin-right: 10px;
}
.circle {
  width: 5px;
  height: 24px;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: 34px;
  height: 34px;
  border: 3px solid var(--primary-color) !important;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.circle2 {
  width: 5px;
  height: 24px;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: 34px;
  height: 34px;
  border: 3px solid #1a1a1a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.tyc {
  height: 24px;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
}
.tyc a {
  color: #ffffff;
  text-decoration-line: underline;
}
.phoneImage {
  text-align: center;
  padding-right: 190px;
}
.phoneImage img {
  max-width: 228px;
  margin: 20px 0 76px 0;
}

.flow-subs-footer {
  display: flex;
  justify-content: space-between;
  margin: 40px auto 0 auto;
  max-width: 1140px;
  width: 100%;
}

.flow-subs-footer > div:nth-child(2) {
  padding-right: 215px;
}

.media-bv {
  height: 24px;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 24px;
  color: #5b595f;
}
.media-bv-form {
  height: 24px;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 24px;
  color: #5b595f;
  display: none;
}

.create {
  width: 219px;
  height: 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 10px;
  text-align: center;
}

.join {
  font-weight: 500;
  font-size: 16px;
  width: 60%;
  margin-top: 35px;
  color: #ffffff;
}

.box-form {
  width: 70%;
  margin-top: 30px;
  margin-bottom: 56px;
}

.password {
  width: 100%;
  align-items: flex-start;
  display: flex;
}

.password > div {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.password > div:nth-child(1) {
  align-items: start;
}

.password > div:nth-child(2) {
  align-items: end;
}

.password input {
  width: 97%;
}

.password .input-error {
  width: 100%;
}

.plans-flow {
  margin: 50px 0 140px 0;
  display: flex;
  align-items: center;
  gap: 30px;
  height: 280px;
}

.plans-flow > div {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;
  padding: 20px;

  width: 200px;
  height: 100%;

  background: linear-gradient(
    141.3deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0) 102.88%
  );
  border-radius: 5px;

  position: relative;
}

.plans-flow-interval-text {
  width: 100%;
  text-align: start;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 20px;

  text-transform: uppercase;
  color: var(--primary-color);
}

.plans-flow-price-text {
  width: 100%;
  text-align: start;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: var(--primary-color);
}

.plans-flow-feature-text {
  width: 100%;
  text-align: start;
  font-weight: 400;
  font-size: 12px;
  text-align: start;

  margin: 0;
  color: #ffffff;
}

.best-offer {
  position: absolute;
  top: -10px;
  right: -20px;
  background: #000000;
  box-sizing: border-box;
  border-radius: 5px 5px 5px 0px;
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  padding: 0 5px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

.change-color {
  color: black;
  /* border: 1px solid #05ff90;
  background: #05ff90; */
}

.btn-flow {
  height: 50px;
  box-sizing: border-box;
  background: transparent;

  margin: auto;
  background-color: var(--primary-color);
  position: absolute;
  bottom: 20px;
}

.btn-flow:hover {
  color: var(--primary-color) !important;
  border: 2px solid var(--primary-color) !important;
  background: transparent !important;
}

.plans-flow-button-black {
  color: black;
}

.plans-flow-button-white {
  color: white;
}

@media (max-width: 991px) {
  .main-box {
    margin: 0 auto;
  }

  .second-box {
    width: 90vw;
  }

  .hack-respon {
    display: none;
  }
  .mrg-btn {
    margin: 15px 0;
  }
}
@media (max-width: 430px) {
  .plans-flow {
    margin: 20px 0;
  }
  .best-offer {
    right: -10px;
  }
  .mrg-btn {
    margin: 10px 0;
  }

  .ms-free {
    margin: 5px 0 20px 0;
  }

  .join {
    width: 90%;
  }

  .box-form {
    width: 100%;
  }

  .flow-subs {
    height: auto;
    max-height: auto !important ;
    overflow: scroll;
  }

  .media-bv-form {
    display: none !important;
  }

  .flow-subs-footer {
    display: block;
  }

  .flow-subs-footer > div {
    width: 100%;
    text-align: center;
  }

  .flow-subs-footer > div:nth-child(2) {
    padding-right: 0;
  }
}
@media (max-width: 380px) {
  .plans-flow {
    margin: 0px 0;
  }
  .best-offer {
    padding: 0 3px;
    line-height: 18px;
    top: 174px;
    left: 82%;
  }
  .mrg-btn {
    margin: 10px 0;
  }
  .btn-flow {
    width: 280px;
    line-height: 20px;
  }
}

@media (max-width: 1400px) {
  .phoneImage {
    padding-right: 95px;
  }
}
@media (max-width: 1210px) {
  .phoneImage {
    display: none;
  }
}
@media (max-width: 991px) {
  .main-box {
    justify-content: center;
  }
  .media-bv-form {
    display: block;
  }
  .tyc {
    width: max-content;
    margin: 0 auto 5px auto;
  }
}
@media (max-width: 420px) {
  .main-box {
    width: 95vw;
  }
  .start-free {
    font-size: 38px;
    line-height: 40px;
  }
  .ms-free {
    margin: 20px 0 20px 0;
  }
  .tyc {
    margin-bottom: 0;
  }
}
@media (max-width: 380px) {
  .main-box {
    margin-top: -18px;
  }
  .start-free {
    font-size: 28px;
    line-height: 30px;
  }
  .ms-free {
    margin: 10px 0 0px 0;
    font-size: 13px;
    line-height: 18px;
  }
  .media-bv-form {
    line-height: 12px;
  }
}

@media (max-width: 420px) {
  .box-form {
    width: 95vw;
    margin: 20px auto;
  }
  .box-form input {
    height: 50px;
  }
  .btn-confirm {
    height: 50px;
  }
}
@media (max-width: 380px) {
  .box-form {
    width: 95vw;
    margin: 10px auto;
  }
  .create {
    font-size: 18px;
    line-height: 18px;
  }
  .box-form input {
    font-size: 14px;
  }
}
