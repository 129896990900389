@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,700');

$primary_color: #5E00F6;
$secondary_color: #232129; 
$primary_font_family: 'Poppins';
$link_color: #878787;
$light_gray: #CECECE;
$alternative_color: #680FFB;
$trial_background: #6969f7;

:export {
	primary_color: $primary_color;
}