@import '../../styles/_variables.scss';

.read-more .rm-toggle {
  font-weight: bold;
  cursor: pointer;
  color: var(--primary-color);
}
.read-more {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-transform: initial;
}
