@import "bootstrap";
@import '../../styles/_variables.scss';


.loading-container {
	position: absolute;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
}