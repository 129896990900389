@import 'bootstrap';
@import '../../styles/_variables.scss';
.main-selego {
  margin-top: 0 !important;
}
.main-item {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 590px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    margin-top: -90px;
  }

  .overlay {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20%, #000000 100%);
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  .row {
    margin: 0;
    width: 100%;
  }

  .col {
    @media screen and (max-width: 768px) {
      padding: 0 40px !important;
    }
    &.container-data {
      padding: 20px 98px 0px 95.89px !important;
    }
  }

  .play-button {
    padding: 10px 15px;
    display: inline-flex;
    align-items: center;
    color: white;
    margin-right: 30px;
    background-color: var(--primary-color);
    @media screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .play {
      display: flex;
      justify-content: center;
      text-align: center;
      flex-direction: column;
    }
    .label {
      text-transform: uppercase;
      font-family: $primary_font_family;
      font-weight: 500;
      margin-left: 5px;
      font-size: 15px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2em;
    }
  }

  .category {
    font-family: $primary_font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.1;
    color: white;
    border: solid 2px white;
    border-radius: 3px;
    letter-spacing: 0.1em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0 30px 0;
    padding: 5px 10px;
    min-height: 28.49px;
    flex-direction: row;
    text-transform: uppercase;
  }

  .category-selego{
    color: black;
    border: solid 2px #E1B83B;
    background-color: #E1B83B;
  }
  


  .title-movie {
    font-family: $primary_font_family;
    font-style: normal;
    font-size: 30px;
    line-height: 29px;
    color: #fff;
    margin-bottom: 10px;
    font-weight: bold !important;
    text-transform: uppercase;
  }

  .author {
    font-family: $primary_font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2em;
    color: #fff;
    text-transform: uppercase;
  }

  .year {
    font-family: $primary_font_family;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.2em;
    color: #fff;
    text-transform: uppercase;
  }

  .list-year {
    margin-bottom: 15px;
  }

  .year-detail {
    margin-bottom: 30px;
  }

  .resume {
    max-width: 700px;
    font-family: $primary_font_family;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #fafafa;
    margin-bottom: 30px;
  }
  .extra-container {
    display: flex;
    flex-direction: row;
    margin-bottom: 31.51px;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    .extra-container {
      flex-direction: column;
      width: 100%;
      align-items: flex-start;
      margin-bottom: 15px;
    }
    .resume {
      margin-bottom: 25px;
    }
    .play-button {
      margin-bottom: 50px;
    }
    .category {
      margin-top: 7px;
      padding: 10px;
    }
  }

  .extra {
    display: flex;

    .quality {
      margin-right: 20px;
    }

    .mins {
      font-family: $primary_font_family;
      font-style: normal;
      font-weight: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 15px;
      color: #e5e5e5;
      margin-right: 15px;
      text-transform: uppercase;

      display: flex;
      flex-direction: row;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }

    .audio,
    .cc {
      font-family: $primary_font_family;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 15px;
      color: #e5e5e5;
      margin-right: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-transform: uppercase;
      svg {
        margin-right: 5px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .main-item {
    .col {
      &.container-data {
        padding: 20px 20px 0px 20px !important;
      }
    }
  }
}

.banner-slider-arrow-left {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px;
  padding-left: 10px;
  padding-right: 15px;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 20%;
  left: 0;
}

.banner-slider-arrow-right {
  background-color: rgba(0, 0, 0, 0.8);
  padding: 15px;
  padding-left: 15px;
  padding-right: 10px;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 20%;
  right: 0;
}

.banner-slider-texts {
  padding: 20px 30px !important;
  margin-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.banner-slider-texts div {
  text-align: left;
}

.banner-slider-title-and-category-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 50%;
}

.banner-slider-title-and-category-container .category {
  margin: 0;
}

.banner-slider-texts .resume {
  width: 75%;
  margin-bottom: 0;
  margin-top: 10px;
  max-width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.banner-slider-current-slide {
  position: absolute;
  top: 10%;
  right: 2%;
  color: white;
}

.banner-slider-type-indicator {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.banner-slider-type-indicator svg {
  color: white;
  margin-right: 10px;
  width: 30px;
  height: 30px;
}

.banner-slider-type-indicator span {
  color: white;
  letter-spacing: 3;
}

.banner-slider-resume-and-play-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.banner-slider-resume-and-play-container .extra-container {
  margin: 0;
}

.banner-slider-link {
  z-index: -1;
}
.banner-slider-current-slide-mobile {
  display: none;
}

@media (max-width: 600px) {
  .banners-slider-arrow-container {
  }

  .banner-slider-arrow-left {
    bottom: 0;
    top: 50%;
    background-color: transparent;
  }

  .banner-slider-arrow-right {
    bottom: 0;
    top: 50%;
    background-color: transparent;
  }

  .banner-slider-texts {
    background: linear-gradient(
      180deg,
      transparent,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.9)
    );
    margin-bottom: 0;
  }

  .banner-slider-type-indicator {
    padding-top: 20px !important;
  }

  .banner-slider-title-and-category-container {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .banner-slider-title-and-category-container .category {
    margin-bottom: 20px;
  }

  .banner-slider-resume-and-play-container {
    display: block;
  }

  .banner-slider-texts .resume {
    width: 100%;
    margin-bottom: 0;
    margin-top: 10px;
    max-width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .banner-slider-resume-and-play-container .play-button {
    margin-top: 20px;
  }

  .banner-slider-current-slide {
    display: none;
  }

  .banner-slider-extra-container {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: center !important;
  }

  .banner-slider-current-slide-mobile {
    display: block;
    color: white;
  }
}
