.button {
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  letter-spacing: 0.2em;
  border: solid 3px transparent;
  background-color: var(--color-primary);
  color: var(--text-color-primary);
  transition: all 300ms ease-in-out;
}

.button--primary:not(.disabled):hover {
  border-color: var(--text-color-primary);
}

.button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.button--secondary:not(.disabled):hover {
  background-color: transparent;
  border-color: var(--color-secondary);
  color:var(--color-secondary);
}

.button--secondary {
  color: var(--text-color-secondary);
  background-color: var(--color-secondary);
}
.button--xsmall {
  font-size: 12px;
  padding: 5px 7px;
  font-weight: 700;
}
.button--small {
  font-size: 12px;
  padding: 10px 16px;
}
.button--medium {
  font-size: 1rem;
  padding: 11px 20px;
}
.button--large {
  font-size: 16px;
  padding: 12px 24px;
}
