@import "bootstrap";
@import "../../styles/_variables.scss";

.season {
  margin-bottom: 20px;

  .image-container {
    position: relative;
  }
  .rec.rec-slider {
    min-height: 100%;
    display: flex;
    > div > div {
      height: 100%;
    }
  }
  .rec-item-wrapper {
    height: 100%;
  }
  .text-selego{
    color: black
  }
  .title {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2em;
    color: #fff;
    height: 70px;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .seasons {
      display: flex;
      flex-wrap: wrap;
      .number-season {
        margin-left: 40px;
        text-decoration: none;
        font-family: Poppins;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #fff;
        cursor: pointer;
        letter-spacing: 0.2em;
        text-transform: capitalize;

        &.active {
          color: var(--primary-color);
        }
      }
    }
  }
  @media screen and (max-width: 990px) {
    .cs-overlay {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 20%;
      z-index: 5;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }
  }
  @media screen and (max-width: 768px) {
    .title {
      padding: 0 40px !important;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 990px) {
    .title {
      padding: 15px !important;
    }
  }
  @media screen and (max-width: 1300px) {
    .carousel-season {
      img {
        height: unset;
        width: calc(100vw / 3);
      }
      .cast-name {
        padding: 10px;
      }
      a.arrow-link {
        display: none;
      }
    }
  }

  .rec-slider {
    min-height: unset !important;
  }

  .carousel-season {
    position: relative;
    @media screen and (min-width: 1300px) {
      margin: 0 -84px;
    }
    div > div > div {
      margin: 0 !important;
    }
    .season-box {
      width: 100%;
    }

    img {
      width: 100%;
      height: 168px;
      object-fit: cover;
    }

    .arrow-link {
      display: flex;
      align-items: center;
      height: 168px;
      padding: 0 30px;
      &:disabled,
      &[disabled] {
        opacity: 0.3;
      }
    }

    .season-details {
      background: #2f2c38;
      width: 100%;
      font-family: var(--font-family);
      display: flex;
      height: 100%;
      padding: 15px;
      min-height: 50px;
      height: 100%;
      max-height: 100px;
      flex-direction: column;
    }
    .season-index {
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 15px;
      color: #fff;
      margin: 0 0 10px 0;
      text-align: left;
    }
    .season-name {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      color: #97959b;
      line-height: 15px;
    }

    .play-button-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0 !important;
      padding-bottom: 0;

      .watched {
        font-family: var(--font-family);
        color: #878787;
        font-style: normal;
        font-weight: normal;
        font-size: 9px;
        line-height: 15px;
        margin-left: 15px;
      }

      .play-button {
        background: var(--primary-color);
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0;
        opacity: 0.7;
        border-radius: 100%;
        color: white;
      }
    }
  }
}

